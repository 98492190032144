import React, { useState, ReactNode } from "react";
import { Switch } from "antd";
import styles from "./UserList.module.css";
import List from "@components/list";
import { PaginationPayload } from "types";
import { DEFAULT_PAGE, IMG_BASE_URL, PAGE_LIMIT } from "@constants/index";
import {
  useChangeStatusUserMutation,
  useGetUsersQuery,
} from "@services/api/index";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";

interface User {
  id: string;
  name: string;
  CNIC: string;
  phone: string;
  status: string;
  isInspector: boolean;
  picture: string;
  email: string;
  designation: string;
  department: string;
  division: string;
  zone: string;
  supervisor: string;
  role: string;
  areasIds: string[];
}

interface UserListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
  searchValue?: string;
}

const UserList: React.FC<UserListProps> = ({
  editComp,
  viewComp,
  searchValue,
}) => {
  const [pagination, setPagination] = useState<PaginationPayload>({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const {
    data: users,
    error,
    isLoading,
  } = useGetUsersQuery({
    ...pagination,
    searchText: searchValue,
  });

  const [
    changeStatusUser,
    { isLoading: changeStatusUserIsloading, error: changeStatusUserError },
  ] = useChangeStatusUserMutation();

  const handleToggleChange = async (checked: boolean, record: User) => {
    // Implement toggle functionality
    const result = await changeStatusUser(record.id).unwrap();
  };

  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: styles.tableCell,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      className: styles.tableCell,
    },
    {
      title: "Directorate",
      dataIndex: "department",
      key: "department",
      className: styles.tableCell,
    },

    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      className: styles.tableCell,
    },
    {
      title: "Assistant Director/Deputy Director",
      dataIndex: "supervisor",
      key: "supervisor",
      className: styles.tableCell,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      className: styles.tableCell,
    },
    {
      title: "Status",
      key: "active",
      render: (record: User) => (
        <Switch
          disabled={changeStatusUserIsloading}
          checked={record?.status == "active"}
          onChange={(checked) => handleToggleChange(checked, record)}
        />
      ),
      className: styles.tableCell,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: User) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_USER) &&
            viewComp &&
            viewComp(record.id)}
          {hasPermission(permissions.EDIT_USER) &&
            editComp &&
            editComp(record.id)}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={users}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />
    </div>
  );
};

export default UserList;
