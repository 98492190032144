import React, { useCallback } from "react";
import { Flex, Skeleton } from "antd";
import { Column, Line } from "@ant-design/charts";
import { useGetDashboardQuery } from "@services/api";
import { Card } from "antd";
import classNames from "classnames";
import styles from "./cards.module.css";
import { icons } from "antd/es/image/PreviewGroup";
// import { Form, Input, Select, Skeleton } from "antd";

import {
  ArrowRightOutlined,
  LoadingOutlined,
  FileTextOutlined,
  CarryOutOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  FileDoneOutlined,
  PlusCircleOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
// interface CardProps {
//   item: string;
// }
const Cards: React.FC = () => {
  const { data, isLoading } = useGetDashboardQuery();
  // let cardSkeleton: any = [6];
  let cardSkeleton = Array(6).fill(null); // An array with 6 elements

  const taskStat = data?.data?.taskStat;
  const societySurveyStat = data?.data?.societySurveyStat;

  const getCardData = useCallback((): any => {
    let cardData: any = [];
    if (taskStat) {
      cardData.push({
        title: "Surveyed Properties ",
        count: taskStat?.totalCount || 0,
        color: "#ffd591",
        icon: <FileTextOutlined />,
      });
      cardData.push({
        title: "Newly Added Properties",
        count: taskStat?.new || 0,
        color: "#eaff8f",
        icon: <PlusCircleOutlined />,
      });
      cardData.push({
        title: "Under Process Properties",
        count: taskStat?.proceeded || 0,
        color: "#91caff",
        icon: <CheckCircleOutlined />,
      });
      cardData.push({
        title: "Disposed Off",
        count: taskStat?.disposal || 0,
        color: "#ffadd2",
        icon: <CarryOutOutlined />,
      });
    }

    if (societySurveyStat) {
      cardData.push({
        title: "Society Surveyed Properties ",
        count: societySurveyStat?.totalCount || 0,
        color: "#ffd591",
        icon: <FileTextOutlined />,
      });
      cardData.push({
        title: "Newly Added Society Properties",
        count: societySurveyStat?.new || 0,
        color: "#eaff8f",
        icon: <PlusCircleOutlined />,
      });
    }

    return cardData;
  }, [data]);

  const columnDataZone =
    data?.data?.statByZone
      ?.filter((item) => item?.zone !== null)
      .map((item) => ({
        zone: item?.zone,
        surveyCount: item?.taskCount,
      })) || [];
  // const zoneColors: { [key: string]: string } = {
  //   "BC South": "#eaff8f",
  //   "BCS CITY": "#91caff",
  //   "BC North": "#eaff8f",
  // };
  // const colors = columnData.map((item) => zoneColors[item.zone] || "#000000");
  const columnConfigZone = {
    data: columnDataZone,
    xField: "zone",
    yField: "surveyCount",
    columnWidthRatio: 0.8,
    // color: colors,
    colorField: "zone",
  };
  const columnDataArea =
    data?.data?.statByArea
      ?.filter((item) => item?.area !== null)
      .map((item) => ({
        area: item?.area,
        taskCount: item?.taskCount,
      })) || [];
  // const colorArea: { [key: string]: string } = {
  //   "BC South": "#eaff8f",
  //   "BCS CITY": "#91caff",
  //   "BC North": "#eaff8f",
  // };
  // const areacolors = columnData.map(
  //   (item) => colorArea[item.zone] || "#000000"
  // );
  const columnConfigArea = {
    data: columnDataArea,
    xField: "area",
    yField: "taskCount",
    columnWidthRatio: 0.8,
    // color: areacolors,
    colorField: "area",
  };

  // const lineData =
  //   data?.data?.statByArea
  //     ?.filter((item) => item?.area !== null)
  //     .map((item) => ({
  //       area:
  //         item?.area?.length > 10
  //           ? item?.area?.substring(0, 8) + "..."
  //           : item?.area,
  //       surveyCount: item?.taskCount,
  //       fullArea: item?.area,
  //     })) || [];
  // const lineConfig = {
  //   data: lineData,
  //   xField: "area",
  //   yField: "surveyCount",
  //   smooth: true,
  //   color: "#2D796D",
  //   lineStyle: {
  //     stroke: "#2D796D",
  //   },
  //   colorField: "#2D796D",
  // };

  // const lineSocietyData =
  //   data?.data?.societySurveyStatBySociety
  //     ?.filter((item: any) => item?.area !== null)
  //     .map((item: any) => ({
  //       society:
  //         (item as any)?.society?.length > 10
  //           ? (item as any)?.society?.substring(0, 8) + "..."
  //           : (item as any)?.society,
  //       surveyCount: (item as any)?.societySurveyCount,
  //       fullSociety: (item as any)?.society,
  //     })) || [];
  // const lineSocietyConfig = {
  //   data: lineSocietyData,
  //   xField: "society",
  //   yField: "surveyCount",
  //   smooth: true,
  //   color: "#2D796D",
  //   lineStyle: {
  //     stroke: "#2D796D",
  //   },
  //   colorField: "#2D796D",
  // };
  const columnDataSociety =
    data?.data?.societySurveyStatBySociety
      ?.filter((item: any) => item?.society !== null)
      .map((item: any) => ({
        society: item?.society,
        societySurveyCount: item?.societySurveyCount,
      })) || [];
  // const colorArea: { [key: string]: string } = {
  //   "BC South": "#eaff8f",
  //   "BCS CITY": "#91caff",
  //   "BC North": "#eaff8f",
  // };
  // const areacolors = columnData.map(
  //   (item) => colorArea[item.zone] || "#000000"
  // );
  const columnConfigSociety = {
    data: columnDataSociety,
    xField: "society",
    yField: "societySurveyCount",
    columnWidthRatio: 0.8,
    // color: areacolors,
    colorField: "society",
  };

  return (
    <>
      <div className={`${styles.dashboardContainer}`}>
        <div className={styles.dashboardGrid}>
          {isLoading
            ? cardSkeleton.map((_, index) => <Skeleton key={index} active />)
            : getCardData().map((data: any, index: number) => (
                <Card
                  key={index}
                  className={styles.dashboardCard}
                  bordered={false}
                  style={{ backgroundColor: data.color }}
                >
                  <div className={styles.cardBody}>
                    <div>
                      <div className="block text-500 font-medium mb-3">
                        {data.title}
                      </div>
                      <div className="text-900 font-small text-l">
                        {data.count}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "flex align-center justify-center border-round",
                        styles.icon
                      )}
                    >
                      {data.icon}
                    </div>
                  </div>
                </Card>
              ))}
        </div>
        {/* <div className="flex flex-col lg:flex-row gap-10 mb-12"> */}
        {/* {data?.data?.statByZone && (
            <div className="w-full lg:w-[45%] border-2 rounded-lg border-grey p-6">
              <Flex gap={20} vertical>
                <label
                  className="text-xl font-bold"
                  style={{ color: "#2D796D" }}
                >
                  Survey Count by Zone
                </label>
                <Column {...columnConfig} />
              </Flex>
            </div>
          )} */}

        <div className="w-full lg:w-[100%] border-2 rounded-lg border-grey mb-12 p-6">
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Flex gap={20} vertical>
              <label className="text-xl font-bold" style={{ color: "#2D796D" }}>
                Survey Count by Zone
              </label>
              <Column {...columnConfigZone} />
            </Flex>
          )}
        </div>
        {/* {data?.data?.statByArea && ( */}
        <div className="w-full lg:w-[100%] border-2 rounded-lg border-grey mb-12 p-6">
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Flex gap={20} vertical>
              <label className="text-xl font-bold" style={{ color: "#2D796D" }}>
                Survey Count by Area
              </label>
              <Column {...columnConfigArea} />

              {/* <Line {...lineConfig} /> */}
            </Flex>
          )}
        </div>
        {/* )} */}
        {/* )} */}
        {/* </div> */}
        {/* {data?.data.societySurveyStatBySociety && ( */}
        <div className="w-full lg:w-[100%] border-2 rounded-lg border-grey mb-12 p-6">
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Flex gap={20} vertical>
              <label className="text-xl font-bold" style={{ color: "#2D796D" }}>
                Survey Count by Society
              </label>
              <Column {...columnConfigSociety} />

              {/* <Line {...lineSocietyConfig} /> */}
            </Flex>
          )}
        </div>
      </div>
    </>
  );
};

export default Cards;
